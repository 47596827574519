import React from "react"
import { navigate } from "gatsby-link"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

function Contact() {
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <form
      name="contact"
      method="post"
      action="/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />
      <p hidden>
        <label>
          Don’t fill this out:{" "}
          <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      <div>
        <label className={`block text-sm text-gray-700`} for="cus_name">
          Name
        </label>
        <input
          className={`w-full px-5 py-1 text-gray-700 bg-gray-200 rounded`}
          id="cus_name"
          name="cus_name"
          type="text"
          required=""
          placeholder="Your Name"
          aria-label="Name"
          onChange={handleChange}
        ></input>
      </div>
      <div className={`mt-2`}>
        <label className={`block text-sm text-gray-700`} for="cus_email">
          Email
        </label>
        <input
          className={`w-full px-5  py-1 text-gray-700 bg-gray-200 rounded`}
          id="cus_email"
          name="cus_email"
          type="text"
          required=""
          placeholder="Your Email"
          aria-label="Email"
          onChange={handleChange}
        ></input>
      </div>
      <div className={`mt-2`}>
        <label className={`block text-sm text-gray-700" for="cus_email`}>
          Telephone
        </label>
        <input
          className={`w-full px-5 py-1 text-gray-700 bg-gray-200 rounded`}
          id="cus_tel"
          name="cus_tel"
          type="text"
          required=""
          placeholder="Your Telephone Nº"
          aria-label="Tel"
          onChange={handleChange}
        ></input>
      </div>
      <div className={`mt-2`}>
        <label className={` block text-sm text-gray-700" for="cus_email`}>
          Message
        </label>
        <textarea
          className={`w-full px-5 py-2 text-gray-700 bg-gray-200 rounded"`}
          id="cus_msg"
          rows="10"
          name="cus_msg"
          type="text"
          required=""
          placeholder="Message"
          aria-label="Message"
          onChange={handleChange}
        ></textarea>
      </div>
      <div className={`mt-4 text-center sm:text-left mb-10 sm:mb-0`}>
        <button
          className={`px-4 py-1 text-white font-light tracking-wider bg-gray-800 hover:text-gray-800 hover:bg-white rounded`}
          type="submit"
        >
          Send
        </button>
      </div>
    </form>
  )
}

export default Contact
